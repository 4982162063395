import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { CoachCard } from "./CoachCard";
import { COACH_INFO } from "./constants";
import { FreeMode } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import { useScreenSize } from "../../hooks";
import { RESIZE_WIDTHS } from "../../constants";

let firstClientX, clientX;

export function CoachCarousel() {
  const carouselRef = useRef(null);
  const screenSize = useScreenSize();
  const carouselPadding = screenSize > RESIZE_WIDTHS.sm ? 80 : 24;

  function preventTouch(e) {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  }

  function touchStart(e) {
    firstClientX = e.touches[0].clientX;
  }

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.addEventListener("touchstart", touchStart);
      carouselRef.current.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (carouselRef.current) {
        carouselRef.current.removeEventListener("touchstart", touchStart);
        carouselRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };
  });

  return (
    <div ref={carouselRef} className="overflow-x-hidden w-full">
      <Swiper
        slidesPerView={"auto"}
        slidesOffsetAfter={carouselPadding}
        slidesOffsetBefore={carouselPadding}
        spaceBetween={24}
        freeMode={true}
        modules={[FreeMode]}
      >
        {COACH_INFO.map(({ name, photoUrl, tags, rankAsset }, i) => (
          <SwiperSlide
            key={`${name}-${i}`}
            style={{ width: "auto", height: "auto" }}
          >
            <CoachCard
              name={name}
              photoUrl={photoUrl}
              tags={tags}
              rankAsset={rankAsset}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
