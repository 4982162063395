import React from "react";
import { CoachCard } from "./CoachCard";
import { COACH_INFO } from "./constants";

export function CoachGrid() {
  return (
    <div className="max-w-[1240px] grid grid-cols-4 gap-6 mt-12 lg:grid-cols-3 mx-20">
      {COACH_INFO.map(({ name, photoUrl, tags, rankAsset }, i) => (
        <CoachCard
          key={`${name}-${i}`}
          name={name}
          photoUrl={photoUrl}
          tags={tags}
          rankAsset={rankAsset}
        />
      ))}
    </div>
  );
}
