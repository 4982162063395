import React from "react";
import { useNavigate } from "react-router-dom";

export function SubsidiaryNav() {
  const navigate = useNavigate();

  return (
    <div className="w-full min-h-[90px] px-20 flex items-center border-b border-dashed border-b-[#80808070] sm:px-6">
      <img
        src={`${process.env.REACT_APP_ASSET_URL}/general/irp-logo.png`}
        className="w-[32px] h-[32px] cursor-pointer"
        onClick={() => navigate("/")}
      />
    </div>
  );
}
