import React from "react";

export function SignUpHeader({
  eyebrowText,
  headerText,
  subHeaderText,
  ...props
}) {
  return (
    <div {...props} className="flex flex-col pb-6">
      <span className="eyebrow pb-6">{eyebrowText}</span>
      <h2 className="pb-1">{headerText}</h2>
      <p className="body-small text-[#CECECE]">{subHeaderText}</p>
    </div>
  );
}
