import React from "react";

export function CoachCard({ name, photoUrl, tags, rankAsset }) {
  return (
    <div className="p-6 max-w-[342px] h-full aspect-[3/4] rounded-xl flex flex-col overflow-hidden relative">
      <img
        className="absolute w-full h-full left-0 top-0 object-cover object-top overflow-hidden"
        src={photoUrl}
      />
      <div className="flex flex-col justify-end h-full z-10">
        <CoreInfo
          name={name}
          photoUrl={photoUrl}
          tags={tags}
          rankAsset={rankAsset}
        />
      </div>
    </div>
  );
}

function CoreInfo({ name, tags, rankAsset }) {
  return (
    <div className="flex flex-col gap-y-3">
      <img className="w-8 h-8" src={rankAsset} />
      <h4>{name}</h4>
      <div className="flex flex-row flex-wrap w-full gap-2">
        {tags.map((tagName, i) => (
          <Tag key={`${tagName}-${i}`} tagName={tagName} />
        ))}
      </div>
    </div>
  );
}

function Tag({ tagName }) {
  return (
    <div className="flex justify-center items-center text-center rounded-2xl py-1 px-3 bg-[#80808029] backdrop-blur">
      <span className="body-label">{tagName}</span>
    </div>
  );
}
