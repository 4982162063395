import React, { useState, useRef, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useScreenSize } from "../../hooks";
import { RESIZE_WIDTHS } from "../../constants";

export function Infographic({ imgOrientation, titleText, bodyText, imgUrl }) {
  const [isMobile, setIsMobile] = useState(false);
  const assetRef = useRef(null);
  const containerRef = useRef(null);
  const screenSize = useScreenSize();

  const { scrollYProgress } = useScroll({
    target: assetRef,
    offset: ["center end", "end end"]
  });
  const fadeIn = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const enterExitLeft = useTransform(scrollYProgress, [0, 1], [-50, 0]);
  const enterExitRight = useTransform(scrollYProgress, [0, 1], [50, 0]);

  const { scrollYProgress: bodyScrollYProgress } = useScroll({
    target: assetRef,
    offset: ["center end", "end end"]
  });
  const delayedFadeIn = useTransform(bodyScrollYProgress, [0.5, 1], [0, 1]);

  useEffect(() => {
    if (screenSize < RESIZE_WIDTHS.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenSize]);

  return (
    <div
      className={`flex ${
        imgOrientation === "right" ? "flex-row" : "flex-row-reverse"
      } items-center w-full gap-[40px] sm:flex-col-reverse sm:gap-8`}
      ref={containerRef}
    >
      <div className="flex justify-center w-[50%] z-10 sm:w-full">
        <div className="max-w-[400px] sm:max-w-full">
          <motion.h3
            className="pb-3"
            style={isMobile ? { opacity: 1 } : { opacity: fadeIn }}
          >
            {titleText}
          </motion.h3>
          <motion.div
            className="text-[#BEBEBE]"
            style={isMobile ? { opacity: 1 } : { opacity: delayedFadeIn }}
          >
            {bodyText}
          </motion.div>
        </div>
      </div>
      <motion.img
        ref={assetRef}
        src={imgUrl}
        className="w-[50%] shadow-package overflow-hidden sm:w-full"
        style={
          isMobile
            ? { opacity: 1, translateX: 0 }
            : {
                opacity: fadeIn,
                translateX:
                  imgOrientation === "right" ? enterExitRight : enterExitLeft
              }
        }
      />
    </div>
  );
}
