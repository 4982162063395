import React from "react";
import { Title } from "../../styled-components/Title";
import TestimonialsBadge from "../../assets/testimonials-badge.svg";
import { TestimonialGrid } from "./TestimonialGrid";

const TITLE_TEXT = "Hear from our alumni";
const SUBTITLE_TEXT =
  "Hear stories from our hardworking alumni that love our product.";

export function TestimonialScreen({ navRef }) {
  return (
    <div
      ref={navRef}
      className="w-full flex flex-col justify-center items-center pt-32 px-20 relative sm:px-6 sm:items-start sm:py-16"
    >
      <div className="flex flex-col justify-center items-center pb-20 sm:items-start sm:pb-14">
        <TestimonialsBadge className="mb-6 w-[146px] h-auto" />
        <Title titleText={TITLE_TEXT} subtitleText={SUBTITLE_TEXT} />
      </div>
      <TestimonialGrid />
      <div className="w-full h-[40%] lg:h-[10%] bg-gradient-to-t from-black from-25% to-transparent to-100% absolute bottom-0"></div>
    </div>
  );
}
