import React, { useState, useEffect } from "react";

export function useScreenSize() {
  const [screenSize, setScreenSize] = useState(undefined);

  useEffect(() => {
    function handleResize() {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenSize;
}

export function useClickOutside(ref, callback) {
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.addEventListener("click", handleOutsideClick);
    };
  }, [ref.current]);
}
