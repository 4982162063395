import React from "react";
import { AFFILIATE_INFO } from "./constants";
import AffiliateBlur from "../../assets/affiliate-blur.svg";

export function AffiliateScreen() {
  return (
    <div className="w-full flex flex-col justify-center items-center py-32 px-20 relative sm:items-start sm:px-6 sm:py-16">
      <div className="w-full max-w-[1240px] flex flex-col gap-y-14">
        {AFFILIATE_INFO.map(
          ({ name, photoUrl, platform, subHeader, body, orientation }, i) => (
            <Affiliate
              key={`${name}-${i}`}
              name={name}
              photoUrl={photoUrl}
              platform={platform}
              subHeader={subHeader}
              body={body}
              orientation={orientation}
            />
          )
        )}
      </div>
      <AffiliateBlur className="absolute -z-10 bottom-0 -my-4" />
    </div>
  );
}

function Affiliate({ name, photoUrl, platform, subHeader, body, orientation }) {
  return (
    <div
      className={`flex items-center gap-x-6 ${
        orientation === "reverse" && "flex-row-reverse"
      } md:flex-col-reverse md:gap-y-6 md:items-start`}
    >
      <AffiliateInfo
        name={name}
        photoUrl={photoUrl}
        platform={platform}
        subHeader={subHeader}
      />
      <h3 className="max-w-[680px]">{body}</h3>
    </div>
  );
}

function AffiliateInfo({ name, photoUrl, platform, subHeader }) {
  return (
    <div className="flex flex-col gap-y-4 md:flex-row md:gap-x-4">
      <img className="rounded-3xl md:w-16 md:h-16" src={photoUrl} />
      <div className="flex flex-col gap-y-1">
        <h4>{name}</h4>
        <div className="flex gap-x-1 items-center">
          {platform}
          <p className="body-small text-[#ffffffb3]">{subHeader}</p>
        </div>
      </div>
    </div>
  );
}
