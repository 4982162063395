import React from "react";

export const TESTIMONIAL_INFO = [
  [
    {
      name: "RuralFiddle",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/ruralfiddle.png`,
      body: "The coaches and community really helped me mentally and mindset wise, coming into IRP with a growth mindset is what will get you the most out of this program. The VOD reviews and map breakdown lessons really helped me become a better player overall and make more impactful plays.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-3.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
    },
    {
      name: "Bunnie",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/bunnie.png`,
      body: "I had little expectations when I first signed up for IRP... but very quickly I realized that the community is helpful, friendly, and fun! I learned a lot from their videos and I use them as references whenever I get lost in solo queue.",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/bunnie.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-3.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-1.png`
    },
    {
      name: "Corvus",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/corvus.png`,
      body: [
        "I started off in gold 2 at the beginning of joining IRP. I started improving from the very first lesson and started improving even more from the VOD reviews (Day is the goat!!). Thanks to all the advice, the lessons, and the friends I made in the community!",
        <div className="mt-[12px]" />,
        "I can not recommend IRP enough. It's quite possibly the best investment I've made in my gaming career."
      ],
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/corvus.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/gold-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/ascendant-1.png`
    }
  ],
  [
    {
      name: "Volkhor",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/volkhor.png`,
      body: "Silver in May, 2022 to Immortal in October, 2022 - GG's",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/volkhor.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/gold-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-1.png`
    },
    {
      name: "Blue Shell",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/blue-shell.png`,
      body: `IRP has been an incredible experience, it even surpassed my expectations. My gameplay has improved to a point where I never thought I would be. The mindset of "hardstuck" has since left my thoughts and I'm confident that I can only improve even more with the fundamentals and game knowledge IRP has provided me. The community within IRP is warm and welcoming and never once did I feel like there was nobody I could ask for help.`,
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/ascendant-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-3.png`
    },
    {
      name: "Christian",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/christian.png`,
      body: "Ladies and gentlemen\nI've been stuck for too long\nDouble rank up :')",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/christian.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-3.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/diamond-1.png`
    }
  ],
  [
    {
      name: "Chriswin",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/chriswin.png`,
      body: "We're going places",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/chriswin.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/ascendant-2.png`
    },
    {
      name: "Rob",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/rob.png`,
      body: "Loved it! They'll teach you everything you need to know about the game and are always there to answer questions when you need it.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-3.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-1.png`
    },
    {
      name: "Secrets",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/secrets.png`,
      body: "Hey coach Evad. I liked the scrims and the 1 on 1s most. Didn't get the experience of going with the same coach each time for 1 on 1s, however regardless the feedback was always a huge help the coaches could always find the biggest thing to fix and that would help towards the quickest improvement, as the little things are not what're holding players in iron-diamond back. If I can work a full time job and do college part time and reach the most improved, I'm confident that many others can as well. IRP is a great program for those lacking direct resources to improve and is great for finding like minded individuals to que up with and talk with when it comes to improving at the game.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/gold-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/diamond-2.png`
    }
  ],
  [
    {
      name: "Badlec",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/badlec.png`,
      body: "I improved at a massive rate and honestly would be even higher ranked then I am now if I hadn't taken a break. If you are thinking about joining it's definitely worth your time as the environment is amazing and you'll make some friends along your climb too.",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/badlec.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/diamond-3.png`
    },
    {
      name: "Enoch",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/enoch.png`,
      body: "The coaches care for you and put in tremendous effort for the players/students. If you love Valorant and truly want to get better at it, I'd really suggest that you join the program.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-2.png`
    },
    {
      name: "Joshuascott",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/joshuascott.png`,
      body: "I can't [understate] how much this program has helped me grow as a player. I truly truly was absolute garbage mechanically when I first started, but I see the climb to Immortal pretty clearly now!",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/iron-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/gold-2.png`
    },
    {
      name: "Helplesshero",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/helplesshero.png`,
      body: "IRP blessed my 2 year hardstuck Silver soul with a wealth of VOD Lessons, Group Lessons, and (most importantly) 1-on-1 VOD Reviews. Every coach in IRP has unique insights and lessons to help you improve in every aspect of your gameplay. They provided me with personal routines to train my movement, aim, and utility usage. If you asked me a month before I joined IRP that I'd be Plat and believing Diamond was not just a possibility but an eventuality for me, I'd say you're crazy. That's the power of IRP.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-2.png`
    }
  ]
];

export const TESTIMONIAL_INFO_LG = [
  [
    {
      name: "RuralFiddle",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/ruralfiddle.png`,
      body: "The coaches and community really helped me mentally and mindset wise, coming into IRP with a growth mindset is what will get you the most out of this program. The VOD reviews and map breakdown lessons really helped me become a better player overall and make more impactful plays.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-3.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
    },
    {
      name: "Bunnie",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/bunnie.png`,
      body: "I had little expectations when I first signed up for IRP... but very quickly I realized that the community is helpful, friendly, and fun! I learned a lot from their videos and I use them as references whenever I get lost in solo queue.",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/bunnie.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/diamond-3.png`
    },
    {
      name: "Corvus",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/corvus.png`,
      body: [
        "I started off in gold 2 at the beginning of joining IRP. I started improving from the very first lesson and started improving even more from the VOD reviews (Day is the goat!!). Thanks to all the advice, the lessons, and the friends I made in the community!",
        <div className="mt-[12px]" />,
        "I can not recommend IRP enough. It's quite possibly the best investment I've made in my gaming career."
      ],
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/corvus.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-2.png`
    }
  ],
  [
    {
      name: "Volkhor",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/volkhor.png`,
      body: "Silver in May, 2022 to Immortal in October, 2022 - GG's",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/volkhor.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-1.png`
    },
    {
      name: "Blue Shell",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/blue-shell.png`,
      body: `IRP has been an incredible experience, it even surpassed my expectations. My gameplay has improved to a point where I never thought I would be. The mindset of "hardstuck" has since left my thoughts and I'm confident that I can only improve even more with the fundamentals and game knowledge IRP has provided me. The community within IRP is warm and welcoming and never once did I feel like there was nobody I could ask for help.`,
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/ascendant-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-3.png`
    },
    {
      name: "Christian",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/christian.png`,
      body: "Ladies and gentlemen\nI've been stuck for too long\nDouble rank up :')",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/christian.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-1.png`
    }
  ],
  [
    {
      name: "Secrets",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/secrets.png`,
      body: "Hey coach Evad. I liked the scrims and the 1 on 1s most. Didn't get the experience of going with the same coach each time for 1 on 1s, however regardless the feedback was always a huge help the coaches could always find the biggest thing to fix and that would help towards the quickest improvement, as the little things are not what're holding players in iron-diamond back. If I can work a full time job and do college part time and reach the most improved, I'm confident that many others can as well. IRP is a great program for those lacking direct resources to improve and is great for finding like minded individuals to que up with and talk with when it comes to improving at the game.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/gold-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/diamond-2.png`
    },
    {
      name: "Chriswin",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/chriswin.png`,
      body: "We're going places",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/chriswin.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/ascendant-2.png`
    },
    {
      name: "Rob",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/rob.png`,
      body: "Loved it! They'll teach you everything you need to know about the game and are always there to answer questions when you need it.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-3.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-1.png`
    }
  ]
];

export const TESTIMONIAL_INFO_MD = [
  [
    {
      name: "RuralFiddle",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/ruralfiddle.png`,
      body: "The coaches and community really helped me mentally and mindset wise, coming into IRP with a growth mindset is what will get you the most out of this program. The VOD reviews and map breakdown lessons really helped me become a better player overall and make more impactful plays.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-3.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
    },
    {
      name: "Bunnie",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/bunnie.png`,
      body: "I had little expectations when I first signed up for IRP... but very quickly I realized that the community is helpful, friendly, and fun! I learned a lot from their videos and I use them as references whenever I get lost in solo queue.",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/bunnie.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/diamond-3.png`
    },
    {
      name: "Corvus",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/corvus.png`,
      body: [
        "I started off in gold 2 at the beginning of joining IRP. I started improving from the very first lesson and started improving even more from the VOD reviews (Day is the goat!!). Thanks to all the advice, the lessons, and the friends I made in the community!",
        <div className="mt-[12px]" />,
        "I can not recommend IRP enough. It's quite possibly the best investment I've made in my gaming career."
      ],
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/corvus.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-2.png`
    },
    {
      name: "Volkhor",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/volkhor.png`,
      body: "Silver in May, 2022 to Immortal in October, 2022 - GG's",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/volkhor.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-2.png`
    }
  ],
  [
    {
      name: "Blue Shell",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/blue-shell.png`,
      body: `IRP has been an incredible experience, it even surpassed my expectations. My gameplay has improved to a point where I never thought I would be. The mindset of "hardstuck" has since left my thoughts and I'm confident that I can only improve even more with the fundamentals and game knowledge IRP has provided me. The community within IRP is warm and welcoming and never once did I feel like there was nobody I could ask for help.`,
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/ascendant-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-3.png`
    },
    {
      name: "Christian",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/christian.png`,
      body: "Ladies and gentlemen\nI've been stuck for too long\nDouble rank up :')",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/christian.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-1.png`
    },
    {
      name: "Secrets",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/secrets.png`,
      body: "Hey coach Evad. I liked the scrims and the 1 on 1s most. Didn't get the experience of going with the same coach each time for 1 on 1s, however regardless the feedback was always a huge help the coaches could always find the biggest thing to fix and that would help towards the quickest improvement, as the little things are not what're holding players in iron-diamond back. If I can work a full time job and do college part time and reach the most improved, I'm confident that many others can as well. IRP is a great program for those lacking direct resources to improve and is great for finding like minded individuals to que up with and talk with when it comes to improving at the game.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/gold-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/diamond-2.png`
    },
    {
      name: "Chriswin",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/chriswin.png`,
      body: "We're going places",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/chriswin.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/ascendant-2.png`
    }
  ]
];

export const TESTIMONIAL_INFO_SM = [
  [
    {
      name: "RuralFiddle",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/ruralfiddle.png`,
      body: "The coaches and community really helped me mentally and mindset wise, coming into IRP with a growth mindset is what will get you the most out of this program. The VOD reviews and map breakdown lessons really helped me become a better player overall and make more impactful plays.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-3.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
    },
    {
      name: "Bunnie",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/bunnie.png`,
      body: "I had little expectations when I first signed up for IRP... but very quickly I realized that the community is helpful, friendly, and fun! I learned a lot from their videos and I use them as references whenever I get lost in solo queue.",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/bunnie.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/diamond-3.png`
    },
    {
      name: "Corvus",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/corvus.png`,
      body: [
        "I started off in gold 2 at the beginning of joining IRP. I started improving from the very first lesson and started improving even more from the VOD reviews (Day is the goat!!). Thanks to all the advice, the lessons, and the friends I made in the community!",
        <div className="mt-[12px]" />,
        "I can not recommend IRP enough. It's quite possibly the best investment I've made in my gaming career."
      ],
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/corvus.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/plat-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-2.png`
    },
    {
      name: "Volkhor",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/volkhor.png`,
      body: "Silver in May, 2022 to Immortal in October, 2022 - GG's",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/volkhor.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/gold-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-1.png`
    },
    {
      name: "Blue Shell",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/blue-shell.png`,
      body: `IRP has been an incredible experience, it even surpassed my expectations. My gameplay has improved to a point where I never thought I would be. The mindset of "hardstuck" has since left my thoughts and I'm confident that I can only improve even more with the fundamentals and game knowledge IRP has provided me. The community within IRP is warm and welcoming and never once did I feel like there was nobody I could ask for help.`,
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/ascendant-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-3.png`
    },
    {
      name: "Christian",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/christian.png`,
      body: "Ladies and gentlemen\nI've been stuck for too long\nDouble rank up :')",
      bodyAssetUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/body-assets/christian.png`,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/silver-1.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/immortal-1.png`
    },
    {
      name: "Secrets",
      photoUrl: `${process.env.REACT_APP_ASSET_URL}/testimonials/avatars/secrets.png`,
      body: "Hey coach Evad. I liked the scrims and the 1 on 1s most. Didn't get the experience of going with the same coach each time for 1 on 1s, however regardless the feedback was always a huge help the coaches could always find the biggest thing to fix and that would help towards the quickest improvement, as the little things are not what're holding players in iron-diamond back. If I can work a full time job and do college part time and reach the most improved, I'm confident that many others can as well. IRP is a great program for those lacking direct resources to improve and is great for finding like minded individuals to que up with and talk with when it comes to improving at the game.",
      bodyAssetUrl: null,
      beforeRank: `${process.env.REACT_APP_ASSET_URL}/ranks/gold-2.png`,
      afterRank: `${process.env.REACT_APP_ASSET_URL}/ranks/diamond-2.png`
    }
  ]
];
