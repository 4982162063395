import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SignUp } from "./pages/SignUp";
import { Schedule } from "./pages/Schedule";
import { Home } from "./pages/Home";
import { Join } from "./pages/Join";

const App = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apply" element={<SignUp />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/join" element={<Join />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default App;
