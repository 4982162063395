import React, { useState, useRef, useEffect } from "react";
import { SignUpHeader } from "../styled-components/SignUpHeader";
import { useNavigate } from "react-router-dom";
import { ButtonSmallWhite } from "../styled-components/Button";
import { SubsidiaryNav } from "../styled-components/SubsidiaryNav";
import { useClickOutside } from "../hooks";
import { FullScreenOverlay } from "../styled-components/FullScreenOverlay";
import Close from "../assets/close.svg";
import HubspotForm from "react-hubspot-form";

const EYEBROW_TEXT = "Step 1 of 3";
const HEADER_TEXT = "Watch the 7 minute video & add some basic info below";
const SUB_HEADER_TEXT =
  "We'll help you implement our Immortal roadmap blueprint and rank up 5 divisions in the next 10 weeks - or you get your money back.";
const BUTTON_TEXT = "Get started";

const FORM_ID = "1fd048e2-9455-4dca-885d-a643fbf50499";
const PORTAL_ID = "40007099";

export function SignUp() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();
  useClickOutside(formRef, closeForm);

  function openForm(e) {
    e.stopPropagation();
    setIsFormOpen(true);
  }

  function closeForm(e) {
    if (e) {
      e.stopPropagation();
    }
    setIsFormOpen(false);
  }

  function onSubmitHandler(event) {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      if (event.data.id === FORM_ID) {
        setIsFormOpen(false);
        navigate("/schedule");
      }
    }
  }

  useEffect(() => {
    window.addEventListener("message", onSubmitHandler);

    return () => {
      window.removeEventListener("message", onSubmitHandler);
    };
  }, []);

  return (
    <div className="title-background w-full h-svh flex flex-col items-center text-white font-jakarta">
      <SubsidiaryNav />
      <div className="flex flex-col max-w-[820px] h-full p-6 border-dashed border-x border-x-[#80808070] grow">
        <SignUpHeader
          eyebrowText={EYEBROW_TEXT}
          headerText={HEADER_TEXT}
          subHeaderText={SUB_HEADER_TEXT}
        />
        <video
          autoPlay
          controls
          controlsList="nodownload"
          className="rounded-xl mb-6"
        >
          <source
            src={`${process.env.REACT_APP_ASSET_URL}/general/intro-video.mp4`}
            type="video/mp4"
          />
        </video>
        <ButtonSmallWhite onClick={openForm}>{BUTTON_TEXT}</ButtonSmallWhite>
        <FullScreenOverlay isOpen={isFormOpen}>
          <div
            ref={formRef}
            className="max-w-[640px] max-h-[calc(100vh-3rem)] h-fit bg-white p-6 m-6 rounded-xl overflow-x-visible overflow-y-scroll relative"
          >
            <div
              className="cursor-pointer absolute right-[12px] top-[12px] p-1 bg-white drop-shadow rounded-[50%]"
              onClick={(e) => closeForm(e)}
            >
              <Close className="text-black w-5 h-5" />
            </div>
            <img
              className="mb-6 rounded-xl"
              src={`${process.env.REACT_APP_ASSET_URL}/general/form-1.png`}
            />
            <HubspotForm portalId={PORTAL_ID} formId={FORM_ID} />
          </div>
        </FullScreenOverlay>
      </div>
    </div>
  );
}
