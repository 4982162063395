import React, { useRef } from "react";
import { AffiliateScreen } from "../screens/AffiliateScreen";
import { CoachScreen } from "../screens/CoachScreen";
import { CourseDetailsScreen } from "../screens/CourseDetailsScreen";
import { FAQScreen } from "../screens/FAQScreen";
import { GuaranteeScreen } from "../screens/GuaranteeScreen";
import { Navbar } from "../screens/Navbar";
import { PackageScreen } from "../screens/PackageScreen";
import { TestimonialScreen } from "../screens/TestimonialScreen";
import { TitleScreen } from "../screens/TitleScreen";

export function Home() {
  const productRef = useRef(null);
  const coachRef = useRef(null);
  const testimonialRef = useRef(null);
  const faqRef = useRef(null);

  return (
    <div className="text-white whitespace-pre-line font-jakarta overflow-hidden relative">
      <Navbar
        productRef={productRef}
        coachRef={coachRef}
        testimonialRef={testimonialRef}
        faqRef={faqRef}
      />
      <TitleScreen />
      <GuaranteeScreen />
      <PackageScreen navRef={productRef} />
      <CourseDetailsScreen />
      <AffiliateScreen />
      <CoachScreen navRef={coachRef} />
      <TestimonialScreen navRef={testimonialRef} />
      <FAQScreen navRef={faqRef} />
    </div>
  );
}
