import React from "react";
import { Title } from "../../styled-components/Title";
import FAQBadge from "../../assets/faq-badge.svg";
import { FAQAccordion } from "./FAQAccordion";
import { ButtonLarge } from "../../styled-components/Button";
import { Link } from "react-router-dom";

const TITLE_TEXT = "Any questions?";

const FOOTER_TITLE_TEXT = "Let's get to work.";
const BUTTON_TEXT = "Apply now";

export function FAQScreen({ navRef }) {
  return (
    <div
      ref={navRef}
      className="w-full flex flex-col justify-center items-center pt-32 px-20 footer-background sm:px-6 sm:items-start sm:pt-16"
    >
      <FAQBadge className="mb-6 w-[72px] h-auto" />
      <Title titleText={TITLE_TEXT} />
      <FAQAccordion />
      <div className="py-32 flex flex-col justify-center items-center gap-y-8 sm:items-start">
        <Title titleText={FOOTER_TITLE_TEXT} />
        <Link to="/apply">
          <ButtonLarge>{BUTTON_TEXT}</ButtonLarge>
        </Link>
      </div>
    </div>
  );
}
