import React from "react";

export const ACCORDION_SECTIONS = {
  general: "General",
  pricing: "Pricing",
  course: "Course"
};

export const ACCORDION_CONTENT = {
  general: [
    {
      id: 1,
      title: "What is IRP?",
      body: [
        "IRP stands for Immortal Roadmap Program and was founded in 2021 by Konpeki to provide an in-depth Valorant coaching experience utilizing cohort-based learning.",
        <div className="mt-[12px]" />,
        "IRP is also the ONLY Valorant coaching service that offers an ",
        <span className="font-bold">
          incredible 500 RR guarantee in only 10 weeks.
        </span>
      ]
    },
    {
      id: 2,
      title: "How do I sign up?",
      body: [
        "If you want to sign up, you must be ",
        <span className="font-bold">serious about improving in Valorant.</span>,
        <div className="mt-[12px]" />,
        "Click the ",
        <span className="font-bold">"Apply"</span>,
        " button at the top or bottom of the home page, which will redirect you to a page with a video to give you more details about the program. There will be an application for you to fill out that will cement your interest in the program.",
        <div className="mt-[12px]" />,
        "Once you've submitted your application, Konpeki and his team will reach out to you to book a free 45-minute strategy call where we can determine if we can truly help you reach your goals."
      ]
    },
    {
      title: "How long is the program?",
      body: [
        "Each season of the Immortal Roadmap Program is 10-weeks long. We have limited spots for each season to ensure personalized support for every student.",
        <div className="mt-[12px]" />,
        "Beyond the 10 weeks, you will continue to have lifetime access to the private Discord server, course content (including future lessons on updates), and community scrims."
      ]
    },
    {
      id: 3,
      title: "How does the 5 division in 10 weeks guarantee work?",
      body: [
        "The rank-up guarantee is different depending on your starting rank. ",
        <span className="font-bold">
          Players starting Platinum and below are guaranteed 5 ranks, Diamond 4
          ranks, and Ascendant+ 300 RR.
        </span>,
        <div className="mt-[12px]" />,
        "This means if you join the program at Gold 2, you are ",
        <span className="font-bold">GUARANTEED to reach Diamond!</span>,
        <div className="mt-[12px]" />,
        "Granted though, you have to put in the work. Our coaching team works very hard to ensure everyone gets the support and resources they need to improve; however, we can only do up to 50% of the work. The other 50% needs to come from the students.",
        <div className="mt-[12px]" />,
        "We will honor this guarantee on the condition that you watch all the lessons, submit all your assignments on time for feedback, attend the facilitated scrims/drills, and schedule your one-on-one VOD reviews on time.",
        <div className="mt-[12px]" />,
        "If you complete everything you are asked to do and still don't rank enough in 10 weeks, we will continue coaching you beyond the Season to guarantee the results you want to see."
      ]
    },
    {
      id: 4,
      title: "What's the minimum skill level required?",
      body: [
        <span className="font-bold">
          IRP will benefit players across all skill levels.
        </span>,
        "\nIron~Plat players will be able to learn and apply the fundamentals of the game very quickly.",
        <div className="mt-[12px]" />,
        "Diamond~Immortal 1 players will benefit tremendously from lessons that break down advanced topics for agent roles and maps.",
        <div className="mt-[12px]" />,
        "Immortal 3+ players will benefit by our coaches helping you cut out any bad habits and teaching you nuanced strategies that are easily replicable in ranked games."
      ]
    }
  ],
  pricing: [
    {
      id: 5,
      title: "How much does IRP cost?",
      body: [
        "We take coaching very seriously and the investment reflects that.",
        <div className="mt-[12px]" />,
        "The goal for ",
        <span className="font-bold">EVERY SINGLE</span>,
        " person we take on through IRP is to ",
        <span className="font-bold">
          rank up 500 RR within the 10 week period
        </span>,
        " of working with Konpeki and the other radiant level coaches.",
        <div className="mt-[12px]" />,
        "You would be investing in a ",
        <span className="font-bold">premium coaching program</span>,
        " that includes weekly lessons, coaching calls, VOD reviews, scrims and more which adds up to over 70 hours of coaching. As a result, the minimum to work with us is in the ",
        <span className="font-bold">4 figure USD mark.</span>,
        " If you are not comfortable investing 4-figures, we're not going to be a good fit at this time. "
      ]
    },
    {
      id: 6,
      title: "How do I know IRP is worth it?",
      body: [
        "IRP is not a boosting service and results are not automatic. ",
        <span className="font-bold">
          IRP contains 70+ hours of coaching over 10 weeks
        </span>,
        " (approximately 7 hours per week) and is aimed for players who are serious about improving and climbing. ",
        <span className="font-bold">
          We have graduated 500+ students through IRP and are so confident in
          our program that we offer a 500 RR rank-up guarantee or your money
          back!
        </span>
      ]
    }
  ],
  course: [
    {
      id: 7,
      title: "What is included in IRP?",
      body: "The Immortal Roadmap Program consists of 2-5 weekly live lessons, 1 weekly one-on-one VOD review, 3 hours of practice matches and scrims with other students/coaches, weekly assignments with feedback, access to the IRP discord with coaches, students, and alumni, lifetime access to IRP resources and guidance, and more..."
    },
    {
      id: 8,
      title: "What happens after the 10 weeks of IRP is over?",
      body: "After the 10 weeks of IRP is over, students are suggested to make-up any assignments and 1-on-1 VOD reviews. Students will have continued access to current and future group lessons that will cover all the different facets of the game as Valorant continues to be updated by Riot Games."
    },
    {
      id: 9,
      title: "What happens if I am busy and can't make it through the 10 weeks?",
      body: [
        "Live group lessons will be uploaded within 24 hours after completion onto the IRP website. 1-on-1 sessions can be made up at later points. Practice matches/scrims will be the only feature that you will be unable to make up at a later time; however, you still can participate in them with future Season participants when space is available.",
        <div className="mt-[12px]" />,
        "The IRP site contains full VODs for all the current and future lessons."
      ]
    }
  ]
};
