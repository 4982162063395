import React from "react";
import { Title } from "../../styled-components/Title";
import WhatYoullGetBadge from "../../assets/what-youll-get-badge.svg";
import { Infographic } from "./Infographic";

const TITLE_TEXT = "The complete package";
const SUBTITLE_TEXT =
  "Learn everything you need to know to reach Immortal+ through our Immortal Roadmap Program.";

const IMG_ORIENTATION = {
  right: "right",
  left: "left"
};

const INFO_TITLE_TEXT_1 = "70+ hours of course content. Whenever you want.";
const INFO_BODY_TEXT_1 =
  "Stop wasting hundreds of hours watching watered down guides and searching for the right videos to watch. We've built an 10 week course featuring the best content that builds on top of each other, step-by-step. Follow along with our live course schedule or revisit any lesson, whenever you want.";

const INFO_TITLE_TEXT_2 = "Weekly 1:1 sessions.";
const INFO_BODY_TEXT_2 = [
  "Instead of letting everything you've learned go to waste, reinforce the topics you've learned through weekly 1:1 live VOD review sessions with a top 100 Radiant coach with VCT experience.",
  <div className="mt-[12px]" />,
  "Instead of looking to fix factors that are out of your control, we'll help you identify your biggest bottlenecks, give you actionable steps for improvement, and keep you accountable through our weekly check-ins."
];

const INFO_TITLE_TEXT_3 = "Weekly assignments.";
const INFO_BODY_TEXT_3 = [
  "Don't let mistakes go unnoticed. Our team of a dozen Radiant coaches will help you identify whether you are practicing and implementing your drills correctly with weekly assignments.",
  <div className="mt-[12px]" />,
  "We not only understand WHY players make common mistakes, but we also know HOW to correct these mistakes in the fastest way so that you can go back to climbing without being stuck."
];

const INFO_TITLE_TEXT_4 =
  "Community scrims and practice sessions. Grow together.";
const INFO_BODY_TEXT_4 = [
  "Identify your win condition.",
  <div className="mt-[12px]" />,
  "Our coaches who have been on VCT Championship-winning teams will teach you the game-winning strategies for every single map in Valorant.",
  <div className="mt-[12px]" />,
  "Learn to implement these strategies through custom 10-man scrims and live server work with players around your skill level. There will be a coach on each team watching your gameplay, listening to your communication, and giving feedback."
];

export function PackageScreen({ navRef }) {
  return (
    <div
      ref={navRef}
      className="package-background w-full flex flex-col items-center py-32 px-20 sm:px-6 sm:items-start sm:py-16"
    >
      <div className="flex flex-col justify-center items-center pb-20 sm:items-start sm:pb-14">
        <WhatYoullGetBadge className="mb-6 w-[172px] h-auto" />
        <Title titleText={TITLE_TEXT} subtitleText={SUBTITLE_TEXT} />
      </div>
      <div className="w-full max-w-[1240px] flex flex-col gap-y-20 sm:gap-y-14">
        <Infographic
          imgOrientation={IMG_ORIENTATION.left}
          titleText={INFO_TITLE_TEXT_1}
          bodyText={INFO_BODY_TEXT_1}
          imgUrl={`${process.env.REACT_APP_ASSET_URL}/package/infographic-1.png`}
        />
        <Infographic
          imgOrientation={IMG_ORIENTATION.right}
          titleText={INFO_TITLE_TEXT_2}
          bodyText={INFO_BODY_TEXT_2}
          imgUrl={`${process.env.REACT_APP_ASSET_URL}/package/infographic-2.png`}
        />
        <Infographic
          imgOrientation={IMG_ORIENTATION.left}
          titleText={INFO_TITLE_TEXT_3}
          bodyText={INFO_BODY_TEXT_3}
          imgUrl={`${process.env.REACT_APP_ASSET_URL}/package/infographic-3.png`}
        />
        <Infographic
          imgOrientation={IMG_ORIENTATION.right}
          titleText={INFO_TITLE_TEXT_4}
          bodyText={INFO_BODY_TEXT_4}
          imgUrl={`${process.env.REACT_APP_ASSET_URL}/package/infographic-4.png`}
        />
      </div>
    </div>
  );
}
