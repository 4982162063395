import React from "react";

export function Title({ titleText, subtitleText, buttonText, ...props }) {
  return (
    <div
      className="flex flex-col justify-center items-center text-center max-w-[680px] sm:items-start sm:text-left"
      {...props}
    >
      <h1>{titleText}</h1>
      {subtitleText && (
        <p className="pt-6 text-[#A5A5A5] body-reg">{subtitleText}</p>
      )}
    </div>
  );
}
