import React from "react";
import { ButtonSmall } from "../../styled-components/Button";

const TITLE_TEXT =
  "IRP is the only premium coaching program that gives you a proven step-by-step roadmap to hit Immortal+";
const BUTTON_TEXT = "Watch the Trailer";

export function TrailerTitle({ openTrailer }) {
  return (
    <div className="flex flex-col justify-center items-center px-20 py-[200px] z-10 sm:items-start sm:px-6">
      <h2 className="max-w-5xl pb-10 text-center sm:text-left">{TITLE_TEXT}</h2>
      <ButtonSmall onClick={(e) => openTrailer(e)}>{BUTTON_TEXT}</ButtonSmall>
    </div>
  );
}
