import React from "react";
import ArrowRight from "../../assets/arrow-right.svg";

export function TestimonialCard({
  photoUrl,
  name,
  body,
  bodyAssetUrl,
  beforeRank,
  afterRank
}) {
  return (
    <div className="flex flex-col gap-y-4 rounded-xl bg-[#191919] p-4">
      <div className="flex gap-x-2 items-center">
        <img className="rounded-[50%] w-8 h-8" src={photoUrl} />
        <h4>{name}</h4>
      </div>
      <div className="text-base text-[#bebebed9]">{body}</div>
      {bodyAssetUrl && (
        <img
          className="rounded-xl h-full object-cover overflow-hidden"
          src={bodyAssetUrl}
        />
      )}
      <div className="flex items-center gap-x-2">
        <img className="w-8 h-8" src={beforeRank} />
        <ArrowRight />
        <img className="w-8 h-8" src={afterRank} />
      </div>
    </div>
  );
}
