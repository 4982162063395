import React, { useState, useEffect } from "react";
import { Title } from "../../styled-components/Title";
import OurCoachesBadge from "../../assets/our-coaches-badge.svg";
import { CoachGrid } from "./CoachGrid";
import { RESIZE_WIDTHS } from "../../constants";
import { CoachCarousel } from "./CoachCarousel";
import { useScreenSize } from "../../hooks";

const TITLE_TEXT = "Reliable, vetted coaching staff";
const SUBTITLE_TEXT =
  "Our coaching staff is vetted through an intense screening process to bring you the highest quality of coaching";

export function CoachScreen({ navRef }) {
  const [showCarousel, setShowCarousel] = useState(false);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (screenSize < RESIZE_WIDTHS.md) {
      setShowCarousel(true);
    } else {
      setShowCarousel(false);
    }
  });

  return (
    <div
      ref={navRef}
      className="coaches-background w-full flex flex-col justify-center items-center py-32 rounded-[2rem] sm:py-16"
    >
      <div className="flex flex-col justify-center items-center px-20 pb-20 sm:px-6 sm:items-start sm:pb-14">
        <OurCoachesBadge className="mb-6 w-[140px] h-auto" />
        <Title titleText={TITLE_TEXT} subtitleText={SUBTITLE_TEXT} />
      </div>
      {showCarousel ? <CoachCarousel /> : <CoachGrid />}
    </div>
  );
}
