import React, { useState, useEffect } from "react";
import { TestimonialCard } from "./TestimonialCard";
import {
  TESTIMONIAL_INFO,
  TESTIMONIAL_INFO_LG,
  TESTIMONIAL_INFO_MD
} from "./constants";
import { useScreenSize } from "../../hooks";
import { RESIZE_WIDTHS } from "../../constants";

export function TestimonialGrid() {
  const [testimonialInfo, setTestimonialInfo] = useState([]);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (screenSize > RESIZE_WIDTHS.lg) {
      setTestimonialInfo(TESTIMONIAL_INFO);
    } else if (
      screenSize < RESIZE_WIDTHS.lg &&
      screenSize >= RESIZE_WIDTHS.md
    ) {
      setTestimonialInfo(TESTIMONIAL_INFO_LG);
    } else if (
      screenSize < RESIZE_WIDTHS.md &&
      screenSize >= RESIZE_WIDTHS.sm
    ) {
      setTestimonialInfo(TESTIMONIAL_INFO_MD);
    } else if (screenSize < RESIZE_WIDTHS.sm) {
      // needs to change to sm list
      setTestimonialInfo(TESTIMONIAL_INFO_LG);
    }
  }, [screenSize]);

  return (
    <div className="max-w-[1240px] testimonial-grid gap-6">
      {testimonialInfo.map((column, i) => (
        <div key={`${column}-${i}`} className="flex flex-col gap-6">
          {column.map(
            (
              { name, photoUrl, body, bodyAssetUrl, beforeRank, afterRank },
              i
            ) => (
              <TestimonialCard
                key={`${name}-${i}`}
                name={name}
                photoUrl={photoUrl}
                body={body}
                bodyAssetUrl={bodyAssetUrl}
                beforeRank={beforeRank}
                afterRank={afterRank}
              />
            )
          )}
        </div>
      ))}
    </div>
  );
}
