import React from "react";
import TikTok from "../../assets/tiktok.svg";
import Youtube from "../../assets/youtube.svg";

export const AFFILIATE_INFO = [
  {
    name: "Linda Dong",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/affiliates/linda.png`,
    platform: <TikTok />,
    subHeader: "17.7M followers",
    body: `"Valorant was my first ever FPS game. IRP helped me so much. I went from missing every shot to actually holding my own in Gold/Plat lobbies. I recommend IRP to anyone looking to get better at Valorant quickly and efficiently!"`,
    orientation: "normal"
  },
  {
    name: "Kariyu",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/affiliates/kariyu.png`,
    platform: <Youtube />,
    subHeader: "266K subscribers",
    body: `“IRP has been a great resource for enhancing my competitive VALORANT experience, not just in knowledge and game sense, but even confidence I go into every game with a very strong idea of what to do now!”`,
    orientation: "reverse"
  },
  {
    name: "Platoon",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/affiliates/platoon.png`,
    platform: <Youtube />,
    subHeader: "1M+ views",
    body: `“IRP is a top notch coaching program with a very well thought out structure for improvement!”`,
    orientation: "normal"
  }
];
