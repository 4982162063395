import React from "react";

export function NavbarLink({ navRef, children, ...props }) {
  const executeScroll = () =>
    navRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <button
      className="button-small-transparent"
      onClick={executeScroll}
      {...props}
    >
      {children}
    </button>
  );
}
