import React from "react";
import { ButtonSmallWhite } from "../styled-components/Button";
import { SignUpHeader } from "../styled-components/SignUpHeader";
import { SubsidiaryNav } from "../styled-components/SubsidiaryNav";

const EYEBROW_TEXT = "Step 3 of 3";
const HEADER_TEXT = "Join our Discord server for your strategy call";
const SUB_HEADER_TEXT =
  "My team and I will contact you through Discord. Joining our server will make communication easier and provide you with more free training resources!";
const BUTTON_TEXT = "Join our discord";
const DISCORD_URL = "https://discord.gg/UnRETVmRpV";

function handleNavigateExternal() {
  window.location.href = DISCORD_URL;
}

export function Join() {
  return (
    <div className="title-background w-full h-svh flex flex-col items-center text-white font-jakarta whitespace-pre-line">
      <SubsidiaryNav />
      <div className="flex flex-col max-w-[820px] h-full p-6 border-dashed border-x border-x-[#80808070] grow">
        <SignUpHeader
          eyebrowText={EYEBROW_TEXT}
          headerText={HEADER_TEXT}
          subHeaderText={SUB_HEADER_TEXT}
        />
        <div className="pb-6">
          <ButtonSmallWhite onClick={handleNavigateExternal}>
            {BUTTON_TEXT}
          </ButtonSmallWhite>
        </div>
        <img
          className="rounded-xl overflow-hidden"
          src={`${process.env.REACT_APP_ASSET_URL}/general/discord-screenshot.png`}
        />
      </div>
    </div>
  );
}
