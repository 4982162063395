import React from "react";

const HEADER_TEXT_1 = "500+";
const BODY_TEXT_1 = "alumni who completed the course";
const HEADER_TEXT_2 = "10+";
const BODY_TEXT_2 = "radiant coaches in the top 0.2% of players";
const HEADER_TEXT_3 = "70+";
const BODY_TEXT_3 = "hours of coaching per student";

export function CourseDetailsScreen() {
  return (
    <div className="w-full flex flex-col items-center py-32 px-20 sm:px-6 sm:py-16">
      <div className="max-w-[1240px] w-full flex justify-center gap-x-10 sm:flex-col sm:py-6">
        <InfoBox headerText={HEADER_TEXT_1} bodyText={BODY_TEXT_1} />
        <InfoBox headerText={HEADER_TEXT_2} bodyText={BODY_TEXT_2} />
        <InfoBox headerText={HEADER_TEXT_3} bodyText={BODY_TEXT_3} />
      </div>
    </div>
  );
}

function InfoBox({ headerText, bodyText }) {
  return (
    <div className="basis-1/3 flex flex-col py-6 gap-2 border-t border-[#80808014]">
      <h1>{headerText}</h1>
      <p className="text-[#A5A5A5] body-reg">{bodyText}</p>
    </div>
  );
}
