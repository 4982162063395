import React, { useEffect, useState, useRef } from "react";
import { Title } from "../../styled-components/Title";
import OurPromiseBadge from "../../assets/our-promise-badge.svg";
import { motion, useInView } from "framer-motion";
import { useScreenSize } from "../../hooks";
import { RESIZE_WIDTHS } from "../../constants";

const TITLE_TEXT = "5 divisions. 10 weeks. Guaranteed.";
const SUBTITLE_TEXT =
  "Feeling hardstuck? If we don't help you rank up at least 500 RR in 10 weeks, you don't pay. We got you covered.";

export function GuaranteeScreen() {
  const assetRef = useRef(null);
  const isInView = useInView(assetRef);
  const [isMobile, setIsMobile] = useState(false);
  const screenSize = useScreenSize();

  const assetUrl = isMobile
    ? `${process.env.REACT_APP_ASSET_URL}/general/emblems-mobile.png`
    : `${process.env.REACT_APP_ASSET_URL}/general/emblems-desktop.png`;

  useEffect(() => {
    if (screenSize < RESIZE_WIDTHS.lg) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenSize]);

  return (
    <div className="w-full flex flex-col justify-center items-center py-32 sm:items-start sm:py-16">
      <div className="flex flex-col justify-center items-center px-20 pb-20 sm:px-6 sm:items-start sm:pb-14">
        <OurPromiseBadge className="mb-6 w-[134px] h-auto" />
        <Title titleText={TITLE_TEXT} subtitleText={SUBTITLE_TEXT} />
      </div>
      <div className="w-full flex justify-center">
        <motion.img
          ref={assetRef}
          className="emblems"
          src={assetUrl}
          style={{
            opacity: isInView ? 1 : 0,
            transition: "ease-in 1s"
          }}
        />
      </div>
    </div>
  );
}
