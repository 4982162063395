import React from "react";

export function FullScreenOverlay({ isOpen, children, ...props }) {
  return (
    isOpen && (
      <div
        {...props}
        className="grid justify-center items-center h-full w-full bg-[#000000b3] overflow-x-hidden overflow-y-scroll fixed top-0 left-0 z-50"
      >
        {children}
      </div>
    )
  );
}
