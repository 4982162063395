import React, { useState, useEffect } from "react";
import Plus from "../../assets/plus-icon.svg";
import Minus from "../../assets/minus-icon.svg";
import { ACCORDION_CONTENT, ACCORDION_SECTIONS } from "./constants";
import { motion, AnimatePresence } from "framer-motion";

export function FAQAccordion() {
  const [selected, setSelected] = useState(null);

  function handleClick(id) {
    if (id === selected) {
      setSelected(null);
    } else {
      setSelected(id);
    }
  }

  return (
    <div className="w-full max-w-[1240px] py-20 flex flex-col gap-y-8 sm:py-14">
      <AccordionSection
        sectionName={ACCORDION_SECTIONS.general}
        section={ACCORDION_CONTENT.general}
        handleClick={handleClick}
        selected={selected}
      />
      <AccordionSection
        sectionName={ACCORDION_SECTIONS.pricing}
        section={ACCORDION_CONTENT.pricing}
        handleClick={handleClick}
        selected={selected}
      />
      <AccordionSection
        sectionName={ACCORDION_SECTIONS.course}
        section={ACCORDION_CONTENT.course}
        handleClick={handleClick}
        selected={selected}
      />
    </div>
  );
}

function AccordionSection({ sectionName, section, handleClick, selected }) {
  return (
    <div className="flex flex-col gap-y-2">
      <span className="eyebrow pl-4">{sectionName}</span>
      {section.map(({ id, title, body }, i) => (
        <AccordionItem
          key={`${title}-${i}`}
          id={id}
          title={title}
          body={body}
          handleClick={handleClick}
          selected={selected}
        />
      ))}
    </div>
  );
}

function AccordionItem({ id, title, body, handleClick, selected }) {
  const isSelected = selected === id;

  const variants = {
    open: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 }
  };

  return (
    <div className="flex flex-col overflow-hidden p-4 gap-y-4 bg-[#80808014] rounded-xl backdrop-blur-2xl">
      <div
        className="flex justify-between cursor-pointer"
        onClick={() => handleClick(id)}
      >
        <h4 className="w-full">{title}</h4>
        {isSelected ? <Minus /> : <Plus />}
      </div>
      <AnimatePresence initial={false}>
        {isSelected && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={variants}
            transition={{ duration: 0.2, ease: "linear" }}
            style={{ maxWidth: "800px" }}
          >
            <span className="body-small text-[#cecece]">{body}</span>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
