import React, { useState, useEffect } from "react";
import { ButtonSmallWhite } from "../../styled-components/Button";
import { NavbarLink } from "./NavbarLink";
import { Link } from "react-router-dom";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";

const NAVBAR_TEXT = {
  products: "Products",
  coaches: "Coaches",
  testimonials: "Testimonials",
  faqs: "FAQ's",
  login: "Login",
  applynow: "Apply Now"
};

const EXTERNAL_LOGIN_URL = "https://www.immortalroadmap.com/login";

const opacityVariants = {
  /** this is the "visible" key and it's correlating styles **/
  visible: { opacity: 1, y: 0 },
  /** this is the "hidden" key and it's correlating styles **/
  hidden: { opacity: 0, y: -50 }
};

export function Navbar({ productRef, coachRef, testimonialRef, faqRef }) {
  const { scrollY } = useScroll();
  const [isHidden, setIsHidden] = useState(false);

  function hideOrShowNavbar() {
    if (scrollY?.current < scrollY?.prev) {
      setIsHidden(false);
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      setIsHidden(true);
    }
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function handleNavigateExternal() {
    window.location.href = EXTERNAL_LOGIN_URL;
  }

  useMotionValueEvent(scrollY, "change", hideOrShowNavbar);

  return (
    <motion.div
      variants={opacityVariants}
      animate={isHidden ? "hidden" : "visible"}
      transition={{ ease: "easeInOut", duration: 0.2 }}
      className="w-svw h-24 flex justify-center items-center fixed z-40"
    >
      <div className="w-full max-w-[1240px] flex px-20 sm:px-6">
        <div className="basis-1/4 flex items-center md:basis-1/2">
          <img
            src={`${process.env.REACT_APP_ASSET_URL}/general/irp-logo.png`}
            className="w-[32px] h-[32px] cursor-pointer"
            onClick={scrollToTop}
          />
        </div>
        <div className="basis-1/2 flex flex-row justify-center items-center md:hidden">
          <div className="flex gap-x-2 p-2 rounded-[100px] border-[#80808014] bg-[#80808014] backdrop-blur-[20px]">
            <NavbarLink navRef={productRef}>{NAVBAR_TEXT.products}</NavbarLink>
            <NavbarLink navRef={coachRef}>{NAVBAR_TEXT.coaches}</NavbarLink>
            <NavbarLink navRef={testimonialRef}>
              {NAVBAR_TEXT.testimonials}
            </NavbarLink>
            <NavbarLink navRef={faqRef}>{NAVBAR_TEXT.faqs}</NavbarLink>
          </div>
        </div>
        <div className="basis-1/4 flex justify-end items-center gap-x-2 md:basis-1/2">
          <NavbarLink onClick={handleNavigateExternal}>
            {NAVBAR_TEXT.login}
          </NavbarLink>
          <Link to="/apply">
            <ButtonSmallWhite>{NAVBAR_TEXT.applynow}</ButtonSmallWhite>
          </Link>
        </div>
      </div>
    </motion.div>
  );
}
