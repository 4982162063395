import React from "react";
import ButtonEllipse from "../assets/button-ellipse.svg";

export function ButtonLarge({ children, variant, ...props }) {
  return (
    <button {...props} className="button-large overflow-hidden relative">
      <div className="w-full px-8 py-4 rounded-[100px] bg-[#0e0e0e66] z-10">
        {children}
      </div>
      <ButtonEllipse className="button-ellipse w-20 absolute bottom-0 -z-10" />
    </button>
  );
}

export function ButtonSmall({ children, variant, ...props }) {
  return (
    <button {...props} className="button-small relative overflow-hidden">
      <div className="w-full px-4 py-2 rounded-[100px] bg-[#0e0e0e66] z-10">
        {children}
      </div>
      <ButtonEllipse className="button-ellipse w-20 absolute bottom-0 -z-10" />
    </button>
  );
}

export function ButtonSmallWhite({ children, variant, ...props }) {
  return (
    <button {...props} className="button-small-white">
      {children}
    </button>
  );
}
