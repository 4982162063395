import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ButtonLarge } from "../../styled-components/Button";
// import { Countdown } from "./Countdown";
import { TrailerTitle } from "./TrailerTitle";
import { motion, useScroll, useTransform } from "framer-motion";
import { useClickOutside, useScreenSize } from "../../hooks";
import { RESIZE_WIDTHS } from "../../constants";
import { FullScreenOverlay } from "../../styled-components/FullScreenOverlay";

const TITLE_TEXT = "Designed to help you reach Immortal+";
const SUBTITLE_TEXT =
  "Rank up 500 RR in 10 weeks guaranteed, or your money back inside our Immortal Roadmap Program";
const BUTTON_TEXT = "Apply Now";

export function TitleScreen() {
  const containerRef = useRef(null);
  const overlayRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isTrailerOpen, setIsTrailerOpen] = useState(false);
  const screenSize = useScreenSize();
  useClickOutside(overlayRef, closeTrailer);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end start"]
  });

  const titleY = useTransform(scrollYProgress, [0, 1], ["0%", "600%"]);
  const titleOpacity = useTransform(scrollYProgress, [0, 0.2], [1, 0.4]);
  const assetY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  function openTrailer(e) {
    e.stopPropagation();
    setIsTrailerOpen(true);
  }

  function closeTrailer() {
    setIsTrailerOpen(false);
  }

  useEffect(() => {
    if (screenSize < RESIZE_WIDTHS.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenSize]);

  return (
    <div ref={containerRef} className="relative">
      <FullScreenOverlay isOpen={isTrailerOpen}>
        <div className="px-6">
          <video
            ref={overlayRef}
            autoPlay
            controls
            controlsList="nodownload"
            className={`${isMobile ? "w-full" : "max-w-[880px]"} rounded-xl`}
          >
            <source
              src={`${process.env.REACT_APP_ASSET_URL}/general/trailer.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
      </FullScreenOverlay>
      <div className="title-background w-full flex flex-col justify-center items-center gap-y-20 pt-64 px-20 overflow-hidden sm:px-6 sm:gap-y-14 sm:pt-40">
        {isMobile ? (
          <>
            <TitleMobile />
            <AssetMobile />
          </>
        ) : (
          <>
            <TitleDesktop y={titleY} opacity={titleOpacity} />
            <AssetDesktop y={assetY} />
          </>
        )}
      </div>
      <div className="w-full flex justify-center items-center relative">
        <img
          src={`${process.env.REACT_APP_ASSET_URL}/general/background.png`}
          className="w-full h-full absolute object-cover object-top rounded-t-[2rem]"
        />
        <TrailerTitle openTrailer={openTrailer} />
        <div className="w-full h-full bg-gradient-to-t from-black from-0% to-transparent to-100% absolute bottom-0"></div>
      </div>
    </div>
  );
}

// Created separate components here to force a rerender here. For some reason, setting parallax styling in-line to only show on desktop forces the parallax effect on mobile when the overlay renders.
// It's a messy fix, but ensures that the components render properly.

function TitleDesktop({ y, opacity }) {
  return (
    <motion.div
      style={{ y, opacity }}
      className="flex flex-col justify-center items-center text-center sm:items-start sm:text-left"
    >
      <div className="flex flex-col justify-center items-center sm:items-start">
        <h1 className="pb-6 max-w-[1024px]">{TITLE_TEXT}</h1>
        <p className="pb-8 max-w-[680px] text-[#A5A5A5] body-reg">
          {SUBTITLE_TEXT}
        </p>
        <Link to="/apply">
          <ButtonLarge>{BUTTON_TEXT}</ButtonLarge>
        </Link>
      </div>
      {/* add this back in if we want countdown functionality */}
      {/* <Countdown className="mb-12" /> */}
    </motion.div>
  );
}

function TitleMobile() {
  return (
    <motion.div
      // ref={titleRefTest}
      style={{ y: 0, opacity: 1 }}
      className="flex flex-col justify-center items-center text-center sm:items-start sm:text-left"
    >
      <div className="flex flex-col justify-center items-center sm:items-start">
        <h1 className="pb-6 max-w-[1024px]">{TITLE_TEXT}</h1>
        <p className="pb-8 max-w-[680px] text-[#A5A5A5] body-reg">
          {SUBTITLE_TEXT}
        </p>
        <Link to="/apply">
          <ButtonLarge>{BUTTON_TEXT}</ButtonLarge>
        </Link>
      </div>
      {/* add this back in if we want countdown functionality */}
      {/* <Countdown className="mb-12" /> */}
    </motion.div>
  );
}

function AssetDesktop({ y }) {
  return (
    <motion.div
      style={{ y }}
      className="flex justify-start max-w-[1440px] h-full mb-32 sm:w-[200%] sm:ml-[100%] sm:mb-16"
    >
      <img
        src={`${process.env.REACT_APP_ASSET_URL}/general/title-screen-asset.png`}
      />
    </motion.div>
  );
}

function AssetMobile() {
  return (
    <motion.div
      style={{ y: 0 }}
      className="flex justify-start max-w-[1440px] h-full mb-32 sm:w-[200%] sm:ml-[100%] sm:mb-16"
    >
      <img
        src={`${process.env.REACT_APP_ASSET_URL}/general/title-screen-asset.png`}
      />
    </motion.div>
  );
}
